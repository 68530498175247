
/* Retrieves a parameter from the query string
    */
export function getQueryStringParameterByName(name, queryString) {
    if (!queryString) queryString = decodeURI(window.location.search);
    if (!queryString) return '';
    if (queryString.substr(0, 1) !== '?') queryString = '?' + queryString;
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    var pattern = '[\\?&]' + name + '(\\[([^\\]]+)\\])+=[^&#]*';
    var o_reg = new RegExp(pattern, 'g');
    var matches = queryString.match(o_reg);

    if (matches && matches.length > 0) {
        var firstMatchArr = matches[0].split('=');
        var splitFirstKey = firstMatchArr[0].split('[');
        splitFirstKey.shift();
        var indexIsObject = [];
        for (var i = 0; i < splitFirstKey.length; i++) {
            if (isNaN(splitFirstKey[i].substring(0, splitFirstKey[i].length - 1))) indexIsObject.push(true); //Index is for an object if key is not a number
            else indexIsObject.push(false); //Otherwise index is for an array
        }
        var parent = indexIsObject[0] ? {} : [];
        for (var j = 0; j < matches.length; j++) {
            var kvp = matches[j].split('=');
            var value = kvp[1];
            var splitKey = kvp[0].split('[');
            splitKey.shift();
            var objToCheck = parent;
            for (var k = 0; k < splitKey.length; k++) {
                var subKey = splitKey[k].substring(0, splitKey[k].length - 1);
                if (k === splitKey.length - 1) {
                    objToCheck[subKey] = value;
                }
                else {
                    if (objToCheck[subKey] === undefined) {
                        if (indexIsObject[k + 1]) objToCheck[subKey] = {};
                        else objToCheck[subKey] = [];
                    }
                }
                objToCheck = objToCheck[subKey];
            }
        }
        return parent;
    }

    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(queryString);
    return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);    
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function sortNaturalAlphaNumeric(a, b, field) {
    a = a || ''; b = b || '';
    var ax = (field ? a[field] : a);
    var bx = (field ? b[field] : b);
    ax = ax ? ax.toLowerCase() : '';
    bx = bx ? bx.toLowerCase() : '';
    //Make case-insensitive
    var t = 1;
    var f = t * -1;

    for (var i = 0; i < Math.max(ax.length, bx.length); i++) {
        if (ax[i] === undefined) return f;
        else if (bx[i] === undefined) return t;
        else {
            var as = ax.substr(i), bs = bx.substr(i);
            var an = parseInt(as), bn = parseInt(bs);
            if (!isNaN(an) && !isNaN(bn)) {
                if (an === bn) {
                    i = i + an.toString().length - 1;
                    continue;
                }
                return an > bn ? t : f;
            } else {
                if (ax[i] === bx[i])
                    continue;
                return ax[i] > bx[i] ? t : f;
            }
        }
    }
    return 0;
}

export function getDistanceBetweenLatLngPoints(lat1, lng1, lat2, lng2) {
    var radiusOfEarth = 6371100;
    var deltaLat = degreeToRadian(lat2 - lat1);
    var deltaLng = degreeToRadian(lng2 - lng1);
    var a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) + Math.cos(degreeToRadian(lat1)) * Math.cos(degreeToRadian(lat2)) * Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return c * radiusOfEarth;
}

export function isAlongSegment(lineLat1, lineLng1, lineLat2, lineLng2, pointLat, pointLng, maxDeviation) {
    var startToEndBearing = getBearing(lineLat1, lineLng1, lineLat2, lineLng2);
    var startToVehDist = getDistanceBetweenLatLngPoints(lineLat1, lineLng1, pointLat, pointLng);
    var startToVehBearing = getBearing(lineLat1, lineLng1, pointLat, pointLng);

    var altitude = Math.abs(startToVehDist * Math.sin(degreeToRadian(Math.abs(startToEndBearing - startToVehBearing))));
    if (altitude > maxDeviation) {
        return false;
    }
    else {
        var distFromStartToProjection = Math.abs(startToVehDist * Math.sin(degreeToRadian(90 - Math.abs(startToEndBearing - startToVehBearing))));
        var distFromEndToProjection = Math.abs(getDistanceBetweenLatLngPoints(lineLat2, lineLng2, pointLat, pointLng) * Math.sin(degreeToRadian(90 - Math.abs(getBearing(lineLat2, lineLng2, lineLat1, lineLng1) - getBearing(lineLat2, lineLng2, pointLat, pointLng)))));

        if ((getDistanceBetweenLatLngPoints(lineLat1, lineLng1, lineLat2, lineLng2) + (maxDeviation / 3)) >= (distFromStartToProjection + distFromEndToProjection)) {
            return true;
        }
    }

    return false;
}

export function getBearing(lat1, lng1, lat2, lng2) {
    var y = Math.sin(degreeToRadian(lng2) - degreeToRadian(lng1)) * Math.cos(degreeToRadian(lat2));
    var x = Math.cos(degreeToRadian(lat1)) * Math.sin(degreeToRadian(lat2)) - Math.sin(degreeToRadian(lat1))
        * Math.cos(degreeToRadian(lat2)) * Math.cos(degreeToRadian(lng2) - degreeToRadian(lng1));
    return Math.abs(((Math.atan2(y, x) + (2 * Math.PI)) % (2 * Math.PI)) * 180 / Math.PI) + (1 / 7200);
}

export function degreeToRadian(angle) {
    return Math.PI * angle / 180.0;
}

export function mergePatternPredictions(predictionsByPattern, predictedPatternTemplate) {
    let route_dir_dic = {};
    predictionsByPattern.forEach(predicPtrn => {
        if (!predicPtrn.patternId) return;

        let dicK = predicPtrn.routeCode + "_" + predicPtrn.directId;
        if (!route_dir_dic[dicK]) {
            route_dir_dic[dicK] = predicPtrn;
        }
        else route_dir_dic[dicK].predictions = route_dir_dic[dicK].predictions.concat(predicPtrn.predictions);
    });

    let patternGroups = [];
    for (let i in route_dir_dic) {
        route_dir_dic[i].predictions.sort((a, b) => a.predictTime.localeCompare(b.predictTime))
        patternGroups.push(route_dir_dic[i]);
    }

    return patternGroups.sort((a, b) => sortNaturalAlphaNumeric(applyTemplate(predictedPatternTemplate, a), applyTemplate(predictedPatternTemplate, b)));
}

export function applyTemplate(template, obj) {
    return template
        .replace(/#RouteName#/, obj.routeName || obj.RouteName || '')
        .replace(/#PatternName#/, obj.patternName || obj.PatternName || '')
        .replace(/#RouteCode#/, obj.routeCode || obj.RouteCode || '')
        .replace(/#PatternCode#/, obj.patternCode || obj.PatternCode || '')
        .replace(/#Direction#/, obj.directName || obj.DirectionName || '')
        .replace(/#StopCode#/, obj.stopName || obj.StopName || '')
        .replace(/#StopName#/, obj.stopCode || obj.StopCode || '')
        .replace(/#HeadSign#/, obj.headSign || obj.HeadSign || '')
        .replace(/#PatternID#/, obj.patternId || obj.PatternID || '')
}

// Convert date from ISO date string
export function dateFromISO(s) {
    let day,
        rx = /^(\d{4}-\d\d-\d\d([tT ][\d:.]*)?)([zZ]|([+-])(\d\d):(\d\d))?$/,
        p = rx.exec(s) || [];
    if (p[1]) {
        day = p[1].split(/\D/);
        for (let i = 0, L = day.length; i < L; i++) {
            day[i] = parseInt(day[i], 10) || 0;
        }
        day[1] -= 1;
        day = new Date(day[0], day[1], day[2], day[3], day[4], day[5], 0);
        if (!day.getDate()) return NaN;
        return day;
    }
    return NaN;
}

export function isNullOrUndefined(val) {
    return val === undefined || val === null;
}
/* Returns true if the provided string is null, empty, or only whitespace */
export function isNullOrWhitespace(str) {
    if (str === undefined || str === null) return true;
    if (str === 0) return false;
    if (typeof str === 'object') return false;
    return !str || (typeof str === 'string' && str.match(/^ *$/)) !== null;
}

export function convertToISODateWithoutTime(date) {
    if (!isValidDateObject(date))
        date = new Date(date);
    return date.toISOString().split("T")[0];
}

export function getToday() {
    let today = new Date().toISOString().split("T")[0];    
    return today;
}

export function isValidDateObject(date) {
    return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}

export function isValidTripDate(tripDate) {
    let today = new Date(getToday());
    tripDate = new Date(tripDate);
    return tripDate >= today;
}

export function addDaysToDate(date, days) {
    if (!isValidDateObject(date))
        date = new Date(date);
    let result = date;
    result.setDate(result.getDate() + days);
    return result;
}



