import axios from 'axios';
import { decode } from 'polyline';
import * as Sentry from '@sentry/browser';

export function includeLocationInfo(addressStr, searchLocation) {
    if (/^[\d.-]+,[\d.-]+$/.test(addressStr)) return addressStr; //If the location is a lat/lng coordinate, don't modify it

    //Include any parts of the search location setting in the address if they aren't already there
    var lowerAddr = addressStr.toLowerCase();
    var locationArr = searchLocation.split(',');
    locationArr.forEach(function (locationPart) {
        if (lowerAddr.indexOf(locationPart.toLowerCase()) === -1) addressStr += ',' + locationPart;
    });
    return addressStr;
}

export function getGoogleTimeStamp(date, hours, minutes, ampm, localServerTimeDiff) {
    minutes = parseInt(minutes);
    hours = parseInt(hours);
    var splitDate = date.split('-');
    if (hours === 12 && ampm === 'AM') hours = 0;
    else if (hours < 12 && ampm === 'PM') hours += 12;
    var tripTime = new Date(new Date(parseInt(splitDate[0]), parseInt(splitDate[1]) - 1, parseInt(splitDate[2]), hours, minutes).getTime() + localServerTimeDiff);
    var baseTime = new Date('1970-01-01T00:00Z');
    return Math.round((-baseTime.getTime() + tripTime.getTime()) / 1000);
}

export function getTripPlanStops(tripPlan) {
    var steps = tripPlan.steps;
    var stopPointArr = [];
    for (var i = 0; i < steps.length; i++) {
        if (steps[i].travelMode === 'TRANSIT') {
            if (steps[i].transit.departure_stop) stopPointArr.push(`${steps[i].transit.departure_stop.location.lat},${steps[i].transit.departure_stop.location.lng}`);
            if (steps[i].transit.arrival_stop) stopPointArr.push(`${steps[i].transit.arrival_stop.location.lat},${steps[i].transit.arrival_stop.location.lng}`);
        }
    };
    //Remove duplicates
    stopPointArr = stopPointArr.filter(function (item, pos) {
        return stopPointArr.indexOf(item) === pos;
    });

    if (stopPointArr.length === 0) return Promise.resolve([]);
    return axios.get('/api/Stop/GetForPlannedTrip', { params: { stopPoints: stopPointArr } });
}

export function getTripPlan(fromAddr, toAddr, tripType, date, hours, minutes, ampm, routeList, searchLocation, localServerTimeDiff) {
    var params = {
        origin: includeLocationInfo(fromAddr, searchLocation),
        destination: includeLocationInfo(toAddr, searchLocation),
        mode: 'transit'
    };

    var timestamp = getGoogleTimeStamp(date, hours, minutes, ampm, localServerTimeDiff);
    if (tripType === 'departure') params.departure_time = timestamp;
    else params.arrival_time = timestamp;

    return axios.get('/api/TripPlan', {
        params: params,
        transformResponse: axios.defaults.transformResponse.concat(function (data, headers) {
            if (headers['content-type'].indexOf('json') === -1) {
                Sentry.captureException(new Error('Trip planner received a non-JSON response from the server: ' + data));
                return {}; //If response is not JSON (most likely meaning it's an error response), don't transform
            }

            //If an unexpected status code was received, log to sentry
            if (data.status !== 'OK' && data.status !== 'ZERO_RESULTS') Sentry.captureException(new Error('Trip planner response contained error status code: ' + data.status));

            var parser = new TripPlanDataParser(data, new Date(timestamp * 1000), tripType, routeList);
            return {
                steps: parser.getSteps(), summary: parser.getSummary()
            };
        })
    })
}

export function ISOToDateAndTimeInfo(ISODateTime) {
    var splitISO = ISODateTime.split('T');
    var dateStr = splitISO[0];
    var splitTime = splitISO[1].split(':');
    var hours = parseInt(splitTime[0]);
    var ampm = 'AM';
    if (hours >= 12) {
        ampm = 'PM';
        if (hours > 12) hours -= 12;
    }
    else if (hours === 0) hours = 12;
    var timeStr = hours + ':' + splitTime[1] + ' ' + ampm;
    return { date: dateStr, time: timeStr, hour: hours.toString(), minute: splitTime[1], ampm: ampm };
}

export function TripPlanDataParser(tripdata, date, tripType, routeList) {
    if (!routeList) routeList = [];
    this.tripdata = tripdata;
    this.date = date;
    this.tripType = tripType;
    this.colorWheel = ['#000095', '#2eb075', '#a30000', '#7a0078', '#8d9800', '#814d05'];
    this.nextColor = function (lastIndex) {
        var len = this.colorWheel.length;
        while (lastIndex >= len - 1) {
            lastIndex = lastIndex - len;
        }
        return this.colorWheel[lastIndex + 1];
    };
    this.getSummary = function () {
        var data = this.tripdata;
        var route0 = data.routes[0];

        if (!route0)
            return {};
        var leg0 = route0.legs[0];
        if (!leg0)
            return {};
        var sumBounds = route0.bounds;
        var arrivalText, arrival, departureText, departure;
        //Google doesn't always return the arrival_time and departure_time fields, so we may need to calculate them
        if (leg0.arrival_time) {
            arrival = new Date(leg0.arrival_time.value);
            arrivalText = leg0.arrival_time.text;
        }
        else {
            arrival = this.tripType === 'departure' ? new Date(this.date.getTime() + (leg0.duration.value * 1000)) : this.date;
            arrivalText = getTimeFromDate(arrival);
        }
        if (leg0.departure_time) {
            departure = new Date(leg0.departure_time.value);
            departureText = leg0.departure_time.text;
        }
        else {
            departure = this.tripType === 'departure' ? this.date : new Date(this.date.getTime() - (leg0.duration.value * 1000));
            departureText = getTimeFromDate(departure);
        }

        var sum = {
            bounds: sumBounds,
            arrivalText: arrivalText,
            arrival: arrival,
            departureText: departureText,
            departure: departure,
            distanceText: leg0.distance.text,
            durationText: leg0.duration.text,
            startAddress: leg0.start_address,
            endAddress: leg0.end_address,
            startLoc: leg0.start_location,
            endLoc: leg0.end_location
        };
        return sum;
        function getTimeFromDate(date) {
            var ampm = 'AM';
            var hours = date.getHours();
            if (hours >= 12) {
                hours -= 12;
                ampm = 'PM';
            }
            if (hours === 0)
                hours = 12;
            var minutes = date.getMinutes();
            var minutesStr = minutes < 10 ? '0' + minutes : minutes.toString();
            return hours + ':' + minutesStr + ' ' + ampm;
        }
    };
    this.getSteps = function () {
        var steps = [];
        var data = this.tripdata;
        var route0 = data.routes[0];
        if (!route0)
            return steps;
        var leg0 = route0.legs[0];
        if (!leg0)
            return steps;
        //leg0.steps[0].polyline
        var colorI = -1;
        var that = this;
        steps = leg0.steps.map(function (p) {
            //Find route taken in this trip plan step (if any) so we can use its information
            var route = null;
            if (p.transit_details && p.transit_details.line && p.transit_details.line.short_name) {
                for (var i = 0; i < routeList.length; i++) {
                    if (routeList[i].routeCode === p.transit_details.line.short_name) {
                        route = routeList[i];
                        break;
                    }
                }
            }
            return {
                distanceText: p.distance.text,
                travelMode: p.travel_mode,
                durationText: p.duration.text,
                htmlInstruction: p.html_instructions,
                line: decode(p.polyline.points),
                stepColor: that.nextColor(colorI++),
                transit: p.transit_details,
                route: route
            };
        });
        return steps;
    };
}