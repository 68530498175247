import axios from 'axios';

let alertDiv;
function updateOnlineStatus(event) {    
    if (!navigator.onLine && !alertDiv) {    
        try {
            // Safari 3.0+ "[object HTMLElementConstructor]" 
            var isSafari = navigator.userAgent.indexOf("Safari") !== -1;            
            alertDiv = document.createElement('div');
            alertDiv.id = 'alertDiv';
            if (isSafari) {
                alertDiv.style.cssText = "text-align:center; position:fixed; display:inline-block; width:100%;min-height:60px;box-sizing: border-box; font-weight:bold; color:#a94442;border-color:#dca7a7; background-image:linear-gradient(to bottom, #f2dede 0%, #e7c3c3 100%);padding:15px; border-radius:4px; z-index:9999";
            } else {
                alertDiv.style = "text-align:center; position:fixed; display:inline-block; width:100%;min-height:60px;box-sizing: border-box; font-weight:bold; color:#a94442;border-color:#dca7a7; background-image:linear-gradient(to bottom, #f2dede 0%, #e7c3c3 100%);padding:15px; border-radius:4px; z-index:9999";
            }

            alertDiv.innerText = "No internet connection is available.";
            document.getElementsByTagName('body')[0].appendChild(alertDiv);
        } catch (e) {
            console.log(e.message)
        }
        
    }
    else if (alertDiv && navigator.onLine) {    
        var elementExists = document.getElementById("alertDiv");
        if (elementExists) {            
            document.getElementsByTagName('body')[0].removeChild(elementExists);        
            alertDiv = null;
        }
    }
}

window.addEventListener('load', updateOnlineStatus);
window.addEventListener('online', updateOnlineStatus);
window.addEventListener('offline', updateOnlineStatus);
window.addEventListener('beforeunload', (e) => {
    if (!navigator.onLine) {
        window.stop();
        e.preventDefault();
        console.log('no connection and if you reload you will loose contents');
    }
});

axios.interceptors.request.use((config) => {
    var connection = navigator.onLine;
    if (!connection) { return Promise.reject('Request can not be processed because no internet connection is available') };
    return config;
}, (error) => {
    console.log(error);
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
     if (error.response && error.response.data && error.response.data.errorMessage)
            console.log(error.response.data.errorMessage);
      else
            console.log(error.message);

    return Promise.reject(error);
});