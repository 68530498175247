import { createStore } from 'redux';
import axios from 'axios';
import { ISOToDateAndTimeInfo, getTripPlan, getTripPlanStops } from '../util/TripPlannerUtil';
import { getQueryStringParameterByName, sortNaturalAlphaNumeric, dateFromISO, applyTemplate } from '../util/util';
import { addFavoriteStop, removeFavoriteStop, getFavoriteStops, addFavoriteRoute, removeFavoriteRoute, getFavoriteRoutes, addFavoritePattern, removeFavoritePattern, getFavoritePatterns, addFavoriteStopOnRoute, removeFavoriteStopOnRoute, getFavoriteStopsOnRoutes, addFavoriteStopOnPattern, removeFavoriteStopOnPattern, getFavoriteStopsOnPatterns } from '../util/FavoritesUtil';

function ReduxReducer(state, action) {
    // This function receives actions and updates the redux state. Some notes:
    // 1. The returned state MUST be a new object. Not just the original state with modifications. Otherwise redux will believe the state didn't change.
    // 2. Any properties of the state which are arrays or objects must be cloned if they're going to be modified, or the components subscribed to those properties won't re-render.
    // 3. We could easily get around the above two restrictions by deep-cloning the state every time (e.g. return JSON.parse(JSON.stringify(state))) but we don't because it will cause unnecessary re-renders of every subscribed component.
    // See: https://redux.js.org/troubleshooting
    var newState = Object.assign({}, state);
    switch (action.type) {
        case 'TOGGLE_ROUTE':
            var selectedRoutes = newState.selectedRoutes.slice(0);
            var foundRoute = false;
            for (var i = 0; i < selectedRoutes.length; i++) {
                if ((selectedRoutes[i].patternID && selectedRoutes[i].patternID === action.route.patternID) || (selectedRoutes[i].routeID && selectedRoutes[i].routeID === action.route.routeID)) {
                    foundRoute = true;
                    selectedRoutes.splice(i, 1);
                    break;
                }
            }
            if (!foundRoute) selectedRoutes.push(action.route);
            newState.selectedRoutes = selectedRoutes;            
            break;
        case 'TOGGLE_SINGLE_ROUTE':
            //Toggles selection of the specified route and deselects all other routes (if any)
            if (state.selectedRoutes.some(function (r) { return r.routeID === action.route.routeID }))
                newState.selectedRoutes = [];
            else
                newState.selectedRoutes = [action.route];            
            break;
        case 'SELECT_SINGLE_ROUTE':
            newState.selectedRoutes = [action.route];
            break;
        case 'SET_ROUTE_LIST':
            newState.routesLoaded = true;
            newState.routeList = action.routeList;
            break;
        case 'SET_PATTERN_LIST':
            newState.allPatterns = action.allPatterns;
            break;
        case 'TOGGLE_SIDEBAR':
            newState.sidebarShown = !state.sidebarShown;
            break;
        case 'HIDE_SIDEBAR':
            newState.sidebarShown = false;
            break;
        case 'SELECT_STOP':
            newState.selectedStop = action.stop;
            break;       
        case 'UPDATE_BROADCASTS':
            //To avoid unnecessary re-rendering, only update broadcasts in the state if the broadcast contents have changed
            if (JSON.stringify(action.globalBroadcasts) !== JSON.stringify(state.globalBroadcasts)) newState.globalBroadcasts = action.globalBroadcasts;
            if (JSON.stringify(action.routeBroadcasts) !== JSON.stringify(state.routeBroadcasts)) newState.routeBroadcasts = action.routeBroadcasts;
            if (JSON.stringify(action.stopBroadcasts) !== JSON.stringify(state.stopBroadcasts)) newState.stopBroadcasts = action.stopBroadcasts;
            break;
        case 'TOGGLE_FAVORITE_STOP':
            if (state.favoriteStops.indexOf(action.stopNumber) === -1) addFavoriteStop(action.stopNumber);
            else removeFavoriteStop(action.stopNumber);
            newState.favoriteStops = getFavoriteStops();
            break;
        case 'TOGGLE_FAVORITE_ROUTE':
            if (state.favoriteRoutes.indexOf(action.routeNumber) === -1) addFavoriteRoute(action.routeNumber);
            else removeFavoriteRoute(action.routeNumber);
            newState.favoriteRoutes = getFavoriteRoutes();
            break;
        case 'TOGGLE_FAVORITE_PATTERN':
            if (state.favoritePatterns.indexOf(action.patternNumber) === -1) addFavoritePattern(action.patternNumber);
            else removeFavoritePattern(action.patternNumber);
            newState.favoritePatterns = getFavoritePatterns();
            break;
        case 'TOGGLE_FAVORITE_STOP_ON_ROUTE':
            if (!state.favoriteStopsOnRoutes.some(function (sr) { return sr.stopNumber === action.stopNumber && sr.routeNumber === action.routeNumber })) addFavoriteStopOnRoute(action.stopNumber, action.routeNumber);
            else removeFavoriteStopOnRoute(action.stopNumber, action.routeNumber);
            newState.favoriteStopsOnRoutes = getFavoriteStopsOnRoutes();
            break;
        case 'TOGGLE_FAVORITE_STOP_ON_PATTERN':
            if (!state.favoriteStopsOnPatterns.some(function (sr) { return sr.stopNumber === action.stopNumber && sr.patternNumber === action.patternNumber })) addFavoriteStopOnPattern(action.stopNumber, action.patternNumber);
            else removeFavoriteStopOnPattern(action.stopNumber, action.patternNumber);
            newState.favoriteStopsOnPatterns = getFavoriteStopsOnPatterns();
            break;
        case 'TOGGLE_HELP_DLG':
            newState.helpDlgShown = !state.helpDlgShown;
            break;
        case 'SHOW_HELP_DLG':
            newState.helpDlgShown = true;
            break;
        case 'HIDE_HELP_DLG':
            newState.helpDlgShown = false;
            break;
        case 'SHOW_TERM_CONDS_DLG':
            if (newState.helpDlgShown) newState.helpDlgShown = false;
            newState.termCondsDlgShown = true;
            break;
        case 'TOGGLE_TERM_CONDS_DLG':
            newState.termCondsDlgShown = !state.termCondsDlgShown;
            break;
        case 'HIDE_TERM_CONDS_DLG':
            newState.termCondsDlgShown = false;
            if (localStorage.getItem("showHelp") === null) {
                localStorage.setItem('showHelp', true);
                newState.helpDlgShown = true;
            }
            break;
        case 'SHOW_ALERTS_DLG':
            newState.alertsDlgShown = true;
            break;
        case 'HIDE_ALERTS_DLG':
            newState.alertsDlgShown = false;
            break;
        case 'SET_TRIP_PLAN':
            newState.tripPlan = action.tripPlan;
            newState.tripPlanTo = null;
            newState.tripPlanFrom = null;
            if (!newState.tripPlan) newState.tripPlanStops = null;
            break;
        case 'SET_TRIP_PLAN_TO':
            newState.tripPlanTo = action.tripPlanTo;
            break;
        case 'SET_TRIP_PLAN_FROM':
            newState.tripPlanFrom = action.tripPlanFrom;
            break;
        case 'SET_TRIP_PLAN_STOPS':
            newState.tripPlanStops = action.tripPlanStops && action.tripPlanStops.length > 0 ? action.tripPlanStops : null;
            break;
        case 'CLEAR_MAP':
            newState.selectedStop = null;
            newState.selectedRoutes = [];
            newState.walkingPath = null;
            newState.shownStops = null;
            newState.currentPosition = null;
            newState.showMyLocation = false;
            newState.tripPlan = null;
            newState.tripPlanTo = null;
            newState.tripPlanFrom = null;
            newState.tripPlanStops = null;
            break;
        case 'CLEAR_MAP_EXCEPT_TRIP_PLAN':
            newState.selectedStop = null;
            newState.selectedRoutes = [];
            newState.walkingPath = null;
            newState.shownStops = null;
            newState.currentPosition = null;
            newState.showMyLocation = false;
            break;
        case 'SET_WALKING_PATH':
            newState.walkingPath = action.walkingPath;            
            break;
        case 'SHOW_STOPS':
            newState.shownStops = action.stops;
            break;
        case 'SET_ALERT_SUBSCRIPTION':
            newState.alertSubscription = action.alertSubscription;
            break;
        case 'TOGGLE_ALERT_DAY_CHOICE':
            newState.alertDayChoice = !state.alertDayChoice;
            break;
        case 'SHOW_ALERT_SAVED_DLG':
            newState.alertSavedDlgShown = true;
            break;
        case 'HIDE_ALERT_SAVED_DLG':
            newState.alertSavedDlgShown = false;
            break;
        case 'HAS_UNREAD_GLOBAL_BROADCASTS':
            newState.hasUnreadGlobalBroadcasts = true;
            break;
        case 'NO_UNREAD_GLOBAL_BROADCASTS':
            newState.hasUnreadGlobalBroadcasts = false;
            break;
        case "ADDRESS_RESULT":            
            newState.addressResult = action.addressResult;
            break;
        case "STOP_RESULT":
            newState.stopResult = action.stopResult;
            break; 
        case "SET_FILTERED_ROUTES":
            newState.filteredRoutes = action.filteredRoutes;
            break;
        case "SHOW_ALL_ROUTES":
            newState.showAllRoutes = action.showAllRoutes;
            break;
        case "SET_INFO_MESSAGE":
            newState.infoMessage = action.infoMessage;
            break;        
        case "SEARCH_TYPE":
            newState.searchType = action.searchType;            
            break;
        case "SET_CURRENT_POSITION":
            newState.currentPosition = action.currentPosition;            
            break;
        case "SHOW_MY_LOCATION":
            newState.showMyLocation = true;
            break;
        case "SET_ROUTE_BROADCAST":
            newState.broadCast = action.broadCast;
            break;
        case "CLEAR_FORM":            
            newState.clearForm = action.clearForm;
            break;
        case "ADDRESS_SEARCH_COMPLETED":
            newState.isAddressSearchCompleted = action.isAddressSearchCompleted;
            break;
        case "SET_SYSTEM_SETTINGS":
            newState.systemSettings = action.settings;
            break;
        case "SET_USER_POSITION":
            newState.userPosition = action.userPosition;
            break;
        case "SET_LAST_PREDICTED_TIME":
            newState.lastPredictedTime = action.lastPredictedTime;
            break;
        default:
            break;
    }
    return newState;
}

var InitialState = {
    selectedRoutes: [],
    selectedPatterns: [],
    selectedStop: null,
    selectedAddress: null,
    shownStops: [],
    tripPlan: null,
    tripPlanFrom: null,
    tripPlanTo: null,
    tripPlanStops: null,
    routeList: [],
    sidebarShown: true,
    globalBroadcasts: [],
    routeBroadcasts: [],
    stopBroadcasts: [],
    broadCast: null,
    helpDlgShown: false,
    termCondsDlgShown: false,
    hasMessage: false, //  var message = getQueryStringParameterByName('message');
    alertsDlgShown: false,
    favoriteRoutes: getFavoriteRoutes(), //Array of route numbers
    favoritePatterns: getFavoritePatterns(), //Array of pattern numbers
    favoriteStops: getFavoriteStops(), //Array of stop numbers
    favoriteStopsOnRoutes: getFavoriteStopsOnRoutes(), //Array of objects with the properties stopNumber and routeNumber
    favoriteStopsOnPatterns: getFavoriteStopsOnPatterns(), //Array of objects with the properties stopNumber and patternNumber    
    walkingPath: null,
    alertSavedDlgShown: false,
    addressResult: null,
    stopResult: null,
    filteredRoutes: null,
    hasUnreadGlobalBroadcasts: false,
    showAllRoutes: false,
    infoMessage: null,
    searchType: '',
    currentPosition: null,
    showMyLocation: false,
    clearForm: false,
    routesLoaded: false,
    allPatterns: [],
    isAddressSearchCompleted: false,
    systemSettings: null,
    userPosition: null,
    lastPredictedTime: null,
    title: window.location.host.indexOf('localhost') !== 0 ? document.title : 'Prediction Portal Development Environment'
};

var store = createStore(ReduxReducer, InitialState);

axios.get('/api/SystemSettings').then(function (settingsResponse) {
    var systemSettings = settingsResponse.data;
    systemSettings.currentServerTime = dateFromISO(systemSettings.currentServerTime);
    systemSettings.localServerTimeDiff = new Date().getTime() - systemSettings.currentServerTime.getTime();

    //If the client has a Google Analytics tracking code configured, asynchronously add Google Analytics to the page
    if (systemSettings.googleAnalyticsTrackingCode) {
        setTimeout(() => {
            (function (i, s, o, g, r, a, m) {
                i['GoogleAnalyticsObject'] = r;
                i[r] = i[r] || function () {
                    (i[r].q = i[r].q || []).push(arguments)
                };
                i[r].l = 1 * new Date();
                a = s.createElement(o);
                m = s.getElementsByTagName(o)[0];
                a.async = 1;
                a.src = g;
                m.parentNode.insertBefore(a, m);
            })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

            window.ga('create', systemSettings.googleAnalyticsTrackingCode, 'auto');
            window.ga('send', 'pageview');
        }, 5);
    }

    store.dispatch({ type: 'SET_SYSTEM_SETTINGS', settings: systemSettings });

    if (systemSettings.hasTermConditions && localStorage.getItem("showTerm") === null) {
        localStorage.setItem('showTerm', true);
        store.dispatch({ type: 'SHOW_TERM_CONDS_DLG' });
    } else if (localStorage.getItem("showHelp") === null) {
        localStorage.setItem('showHelp', true);
        store.dispatch({ type: 'SHOW_HELP_DLG' });
    }
       
    //Load list of routes when page loads
    LoadRoutes(systemSettings);
}).catch(err=> console.log('Error loading system settings'));

export function LoadRoutes(systemSettings) {
    axios.get('/api/Route').then(function (response) {
        var data = response.data;
        var routeList = [];
        //If a route/pattern code to show on load was passed in the query string, we need to automatically select that route
        var preselectedRoutePatternCode = getQueryStringParameterByName('code');
        var preselectedRoutePattern = null;
        if (systemSettings.showRouteSelection) {
            //Create a list of routes
            var routeNumsDic = {};
            for (let i = data.length - 1; i >= 0; i--) {
                if (!routeNumsDic[data[i].routeNumber]) {
                    var route = {
                        routeID: data[i].routeID,
                        routeNumber: data[i].routeNumber,
                        routeName: data[i].routeName,
                        routeCode: data[i].routeCode,
                        color: data[i].color,
                        patternIDs: [data[i].patternID],
                        label: applyTemplate(systemSettings.sidebarPatternTemplate, data[i])
                    };
                    routeNumsDic[data[i].routeNumber] = route;
                    if (preselectedRoutePatternCode && route.routeCode === preselectedRoutePatternCode) preselectedRoutePattern = route;
                    routeList.push(route);
                }
                else routeNumsDic[data[i].routeNumber].patternIDs.push(data[i].patternID);
            }

            routeList.forEach(r => r.patternIDs.sort()); //Pattern IDs should be sorted since sorted pattern IDs are used as the cache key by the CalculateRoutePathService
        }
        else {
            //Create a list of patterns
            for (let i = data.length - 1; i >= 0; i--) {
                var pattern = {
                    patternID: data[i].patternID,
                    patternName: data[i].patternName,
                    patternNumber: data[i].patternNumber,
                    patternCode: data[i].patternCode,
                    routeNumber: data[i].routeNumber,
                    routeName: data[i].routeName,
                    routeCode: data[i].routeCode,
                    directionID: data[i].directionID,
                    directionName: data[i].directionName,
                    color: data[i].color,
                    label: applyTemplate(systemSettings.sidebarPatternTemplate, data[i])
                };
                if (preselectedRoutePatternCode && pattern.patternCode === preselectedRoutePatternCode) preselectedRoutePattern = pattern;
                routeList.push(pattern);
            }
        }

        routeList.sort(function (a, b) {
            return sortNaturalAlphaNumeric(a, b, 'label');
        });
        store.dispatch({ type: 'SET_ROUTE_LIST', routeList: routeList });
        store.dispatch({ type: 'SET_PATTERN_LIST', allPatterns: data });
        if (preselectedRoutePattern) {
            preselectedRoutePattern.shouldZoom = true;
            store.dispatch({ type: 'SELECT_SINGLE_ROUTE', route: preselectedRoutePattern });
            store.dispatch({ type: 'HIDE_SIDEBAR' });
        }
    });
}

//If a trip plan request was passed in the query string, we need to automatically load and display that trip plan
var tripStart = getQueryStringParameterByName('start');
var tripEnd = getQueryStringParameterByName('destination');
var departureTime = getQueryStringParameterByName('departureTime');
var arrivalTime = getQueryStringParameterByName('arrivalTime');
if (tripStart && tripEnd) {
    var isoRegex = /\d{4}-[0-1][0-9]-[0-3][0-9]T[0-2][0-9]:[0-5][0-9](:[0-5][0-9])?/;
    if ((departureTime && isoRegex.test(departureTime)) || (arrivalTime && isoRegex.test(arrivalTime))) {
        var parsedDateTime, tripType;
        if (departureTime) {
            parsedDateTime = ISOToDateAndTimeInfo(departureTime);
            tripType = 'departure';
        }
        else {
            parsedDateTime = ISOToDateAndTimeInfo(arrivalTime);
            tripType = 'arrival';
        }
        getTripPlan(tripStart, tripEnd, tripType, parsedDateTime.date, parsedDateTime.hour, parsedDateTime.minute, parsedDateTime.ampm).then(function (response) {
            var tripPlan = response.data;
            store.dispatch({ type: 'SET_TRIP_PLAN', tripPlan: tripPlan });
            store.dispatch({ type: 'HIDE_SIDEBAR' });
            getTripPlanStops(tripPlan).then(function (response) {
                store.dispatch({ type: 'SHOW_STOPS', stops: response.data });
            });
        })
            .catch(function (error) {
                console.log(error);
                alert('Unable to load trip plan');
            })
    }
}

//Every 30 seconds, load broadcasts
setInterval((function loadBroadcasts() {
    axios.get('/api/Broadcast').then(function (response) {
        var data = response.data;
        var globalBroadcasts = [];
        var routeBroadcasts = [];
        var stopBroadcasts = [];
        for (var i = 0; i < data.length; i++) {
            if (data[i].routeNum || data[i].patternId) routeBroadcasts.push(data[i]);
            else if (data[i].stopNum) stopBroadcasts.push(data[i]);
            else globalBroadcasts.push(data[i]);
        }
        store.dispatch({ type: 'UPDATE_BROADCASTS', globalBroadcasts: globalBroadcasts, routeBroadcasts: routeBroadcasts, stopBroadcasts: stopBroadcasts });
    });
    return loadBroadcasts;
})(), 30000);

export default store;