import 'react-app-polyfill/ie11';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store/ReduxReducer';
import LoadingScreen from './components/shared/LoadingScreen';
import registerServiceWorker from './registerServiceWorker';
import App from './App';
import * as Sentry from '@sentry/browser';
import './util/OfflineFunctinality';

//Initialize JavaScript error tracking with Sentry unless we're running on localhost
if (window.location.host.indexOf('localhost') !== 0) {
    Sentry.init({
        ignoreErrors: ['Network request failed', 'Failed to fetch', 'NetworkError', 'Network Error', 'withrealtime/messaging', 'UnhandledRejection'], // Prevent network errors from consuming our quota
        dsn: 'https://7e4569400a1546578b9fb9e7d902caf8@sentry.io/1384406'
    });
}
else document.title = 'Prediction Portal Development Environment'

// IE11 array find polyfill from https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
    // eslint-disable-next-line no-extend-native
    Object.defineProperty(Array.prototype, 'find', {
        value: function (predicate) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];

            // 5. Let k be 0.
            var k = 0;

            // 6. Repeat, while k < len
            while (k < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, � kValue, k, O �)).
                // d. If testResult is true, return kValue.
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }
                // e. Increase k by 1.
                k++;
            }

            // 7. Return undefined.
            return undefined;
        },
        configurable: true,
        writable: true
    });
}

// IE11 string includes polyfill from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/includes
if (!String.prototype.includes) {
    // eslint-disable-next-line no-extend-native
    String.prototype.includes = function (search, start) {
        // eslint-disable-next-line strict
        'use strict';
        if (typeof start !== 'number') {
            start = 0;
        }

        if (start + search.length > this.length) {
            return false;
        } else {
            return this.indexOf(search, start) !== -1;
        }
    };
}

// IE11 Number isNaN polyfill from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isNaN
// eslint-disable-next-line no-extend-native
Number.isNaN = Number.isNaN || function (value) {
    // eslint-disable-next-line no-self-compare
    return value !== value;
}


const rootElement = document.getElementById('root');

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<LoadingScreen />}>
            <App />
        </Suspense>
    </Provider>,
    rootElement);

registerServiceWorker();
