import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingScreen from './components/shared/LoadingScreen';

class App extends Component {

    state = {
        isMobile: window.outerWidth <= 900
    };

    componentDidMount() {
        window.onresize = () => {
            if (window.outerWidth <= 900 && !this.state.isMobile) this.setState({ isMobile: true });
            else if (window.outerWidth > 900 && this.state.isMobile) this.setState({ isMobile: false });
        }
    }

    render() {
        if (!this.props.systemSettings) return <LoadingScreen />;

        const pathName = window.location.pathname.toLowerCase();
        //Use React.Lazy() so that JS/CSS for mobile version is never loaded if the user stays desktop-only, and vice-versa. See: https://reactjs.org/docs/code-splitting.html
        if (this.props.systemSettings.serviceDown) {
            const ServiceDown = React.lazy(() => import('./components/ServiceDown'));
            return <ServiceDown />;
        }
        else if (pathName === '/home/predictionsign' || pathName === '/predictionsign') {
            //Load prediction sign if this is a prediction sign URL
            const PredictionSign = React.lazy(() => import('./components/PredictionSign'));
            return <PredictionSign />;
        }
        else if (this.state.isMobile) {
            const MobilePredictionPortal = React.lazy(() => import('./components/MobilePredictionPortal'));
            return <MobilePredictionPortal />;
        }
        else {
            const DesktopPredictionPortal = React.lazy(() => import('./components/DesktopPredictionPortal'));
            return <DesktopPredictionPortal />;
        }
    }
}

export default connect((state) => {
    return { systemSettings: state.systemSettings };
})(App);