export function addFavoriteRoute(routeNumber) {
    var routeArr = getFavoriteRoutes();
    routeArr.push(routeNumber);
    window.localStorage.setItem('FavoredMergedRoute', serialize(routeArr));
}

export function addFavoritePattern(patternNumber) {
    var patternArr = getFavoritePatterns();
    patternArr.push(patternNumber);
    window.localStorage.setItem('FavoredPattern', serialize(patternArr));
}

export function addFavoriteStop(stopNumber) {
    var stopArr = getFavoriteStops();
    stopArr.push(stopNumber);
    window.localStorage.setItem('FavoredStops', serialize(stopArr));
}

export function addFavoriteStopOnPattern(stopNumber, patternNumber) {
    var stopOnPatternArr = getFavoriteStopsOnPatterns();
    stopOnPatternArr.push({ patternNumber: patternNumber, stopNumber: stopNumber });
    window.localStorage.setItem('FavoredStopsOnRoute', serialize(stopOnPatternArr));
}

export function addFavoriteStopOnRoute(stopNumber, routeNumber) {
    var stopOnRouteArr = getFavoriteStopsOnRoutes();
    stopOnRouteArr.push({ routeNumber: routeNumber, stopNumber: stopNumber });
    window.localStorage.setItem('FavoredStopsOnMergedRoute', serialize(stopOnRouteArr));
}

export function removeFavoriteRoute(routeNumber) {
    var routeArr = getFavoriteRoutes();
    var routeIndex = routeArr.indexOf(routeNumber);
    if (routeIndex !== -1) {
        routeArr.splice(routeIndex, 1);
        window.localStorage.setItem('FavoredMergedRoute', serialize(routeArr));
    }
}

export function removeFavoritePattern(patternNumber) {
    var patternArr = getFavoritePatterns();
    var patternIndex = patternArr.indexOf(patternNumber);
    if (patternIndex !== -1) {
        patternArr.splice(patternIndex, 1);
        window.localStorage.setItem('FavoredPattern', serialize(patternArr));
    }
}

export function removeFavoriteStop(stopNumber) {
    var stopArr = getFavoriteStops();
    var stopIndex = stopArr.indexOf(stopNumber);
    if (stopIndex !== -1) {
        stopArr.splice(stopIndex, 1);
        window.localStorage.setItem('FavoredStops', serialize(stopArr));
    }
}

export function removeFavoriteStopOnRoute(stopNumber, routeNumber) {
    var stopOnRouteArr = getFavoriteStopsOnRoutes();
    for (var i = 0; i < stopOnRouteArr.length; i++) {
        if (stopOnRouteArr[i].stopNumber === stopNumber && stopOnRouteArr[i].routeNumber === routeNumber) {
            stopOnRouteArr.splice(i, 1);
            break;
        }
    }
    window.localStorage.setItem('FavoredStopsOnMergedRoute', serialize(stopOnRouteArr));
}

export function removeFavoriteStopOnPattern(stopNumber, patternNumber) {
    var stopOnPatternArr = getFavoriteStopsOnPatterns();
    for (var i = 0; i < stopOnPatternArr.length; i++) {
        if (stopOnPatternArr[i].stopNumber === stopNumber && stopOnPatternArr[i].patternNumber === patternNumber) {
            stopOnPatternArr.splice(i, 1);
            break;
        }
    }
    window.localStorage.setItem('FavoredStopsOnRoute', serialize(stopOnPatternArr));
}

export function getFavoritePatterns() {
    var storedFavorites = window.localStorage.getItem('FavoredPattern');
    if (storedFavorites) return deserialize(storedFavorites);

    //For backwards-compatibility with favorites saved before 5.1.3
    var favoriteCookie = getCookie('FavoredPattern');
    if (favoriteCookie) return deserialize(favoriteCookie);
    favoriteCookie = getCookie('favoritePatterns');
    if (!favoriteCookie) return [];
    return favoriteCookie.split(',').map(function (f) { return parseInt(f) });
}

export function getFavoriteRoutes() {
    var storedFavorites = window.localStorage.getItem('FavoredMergedRoute');
    if (storedFavorites) return deserialize(storedFavorites);

    //For backwards-compatibility with favorites saved before 5.1.3
    var favoriteCookie = getCookie('FavoredMergedRoute');
    if (favoriteCookie) return deserialize(favoriteCookie);
    favoriteCookie = getCookie('favoriteRoutes');
    if (!favoriteCookie) return [];
    return favoriteCookie.split(',').map(function (f) { return parseInt(f) });
}

export function getFavoriteStops() {
    var storedFavorites = window.localStorage.getItem('FavoredStops');
    if (storedFavorites) return deserialize(storedFavorites);

    //For backwards-compatibility with favorites saved before 5.1.3
    var favoriteCookie = getCookie('FavoredStops');
    if (favoriteCookie) return deserialize(favoriteCookie);
    favoriteCookie = getCookie('favoriteStops');
    if (!favoriteCookie) return [];
    return favoriteCookie.split(',').map(function (f) { return parseInt(f) });
}

export function getFavoriteStopsOnRoutes() {
    var storedFavorites = window.localStorage.getItem('FavoredStopsOnMergedRoute');
    if (storedFavorites) return deserialize(storedFavorites);

    //For backwards-compatibility with favorites saved before 5.1.3
    var favoriteCookie = getCookie('FavoredStopsOnMergedRoute');
    if (!favoriteCookie) return [];
    return deserialize(favoriteCookie).map(function (f) {
        var splitCookie = f.split('-');
        return {
            routeNumber: parseInt(splitCookie[0]),
            stopNumber: parseInt(splitCookie[1])
        }
    });
}

export function getFavoriteStopsOnPatterns() {
    var storedFavorites = window.localStorage.getItem('FavoredStopsOnRoute');
    if (storedFavorites) return deserialize(storedFavorites);

    //For backwards-compatibility with favorites saved before 5.1.3
    var favoriteCookie = getCookie('FavoredStopsOnRoute');
    if (!favoriteCookie) return [];
    return deserialize(favoriteCookie).map(function (f) {
        var splitCookie = f.split('-');
        return {
            patternNumber: parseInt(splitCookie[0]),
            stopNumber: parseInt(splitCookie[1])
        }
    });
}

function getCookie(c_name) {
    var i, x, y, ARRcookies = document.cookie.split(";");
    for (i = 0; i < ARRcookies.length; i++) {
        x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
        y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
        x = x.replace(/^\s+|\s+$/g, "");
        if (x === c_name) {
            return unescape(y);
        }
    }
}

/** internally use encodeURI and JSON serializer */
function serialize(object) {
    if (!object) return "";
    return JSON.stringify(object);
}

function deserialize(encodedJSON) {
    var json = decodeURI(encodedJSON);
    return JSON.parse(json);
}
